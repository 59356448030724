#WebTitle {
    margin:0;
    position: relative;
    top: 1%;
    text-align:center;
    background-color:bisque;
    font-size: 100px;
    text-shadow: 0px 0px 5px red;
}
#TitleComment1 {
    margin-top:0;
    margin-bottom:1%;
    color:rgb(0, 20, 50);
    height:3%;
    text-align:center;
    background-color:bisque;
}
